import React from "react";

const NotFound = () => (
  <>
    <h1>Page Not Found</h1>
    <p>Oops, we could not find this page!</p>
  </>
);

export default NotFound;
